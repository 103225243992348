import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

const app = createApp(App);

app.use(router);
app.use(store);

app.provide("store", store);
app.provide("router", router);
app.component("Loading", Loading);

app.mount("#app");
