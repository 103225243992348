<template>
  <div class="container-fluid" style="padding: 0; overflow-x: hidden">
    <router-view></router-view>
  </div>
</template>

<style>
@import "~sweetalert2/dist/sweetalert2.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import "~bootstrap-icons/font/bootstrap-icons.css";

:root {
  --color: #6C8154;
  --color_sub: #BED630;
}

normal-text {
  color: white;
  font-size: 18px;
}


html,
body {
  height: 100%;
}



.th {
  font-family: IBMPlexSansThai, sans-serif;
}

.en {
  font-family: Poppins, sans-serif;
}

.t0 {
 font-size: 35px;
}

.t1 {
  font-size: 20px;
 
}

.t2 {
  font-size: 16px;

}

.t3 {
  font-size: 14px;

}

.t4 {
  font-size: 11px;

}


.center {
  text-align: center;
}

.button {
  color: #373737 !important;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  border-radius: 25px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 8px 10px 8px 10px;
}

.button:hover,
.button:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.button:hover {
  transform: translateY(-1px);
}

.button:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.swal-confirm-btn {
  position: relative;
  box-sizing: border-box;
  height: auto;
  text-align: center;
  border-style: solid;
  border-width: 2px;
  border-color: var(--color);
  border-radius: 10px;
  background-color: var(--color);
  padding: 16px 16px 16px 16px;
}

.swal-confirm-btn:hover,
.swal-confirm-btn:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.swal-confirm-btn:hover {
  transform: translateY(-1px);
}

.swal-confirm-btn:active {
  background-color: #50613d;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}


.swal-cancle-btn {

  position: relative;
  box-sizing: border-box;
  height: auto;
  text-align: center;
  border-style: solid;
  border-width: 2px;
  color: var(--color) !important;
  border-color: var(--color);
  border-radius: 10px;
  background-color: white;
  padding: 16px 16px 16px 16px;
}

.swal-cancle-btn:hover,
.swal-cancle-btn:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.swal-cancle-btn:hover {
  transform: translateY(-1px);
}

.swal-cancle-btn:active {
  background-color:#efefef;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}


</style>
