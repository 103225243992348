<template>
  <loading :active="showLoading" :can-cancel="false" :color="color" loader="dots" />
  <div class="header pb-4">
    <div class="row mb-5">
      <div class="col-5">
        <img class="logo" src="~@/assets/logo-text.png" />
      </div>
      <div class="col-12 text-end pe-5">

        <div class="th t2 text-end mt-5 me-5 font-weight-light" style="color:#BED630">
          สมัครสมาชิก
        </div>
        <div class="th t2 text-end me-5 font-weight-light text" style="font-size: small;">
          <a href="https://www.w3schools.com">

            กดที่นี่
          </a>
        </div>

        <img src="~@/assets/profile.png" class="profile-pic" />
      </div>
    </div>

    <div class="row mb-3 justify-content-center">


      <div class="header-box col-8 th t1 font-weight-normal">
        ค่าบริการการจัดส่งสินค้า
      </div>

    </div>

    <div class="row mb-2 justify-content-center ">
      <div class="th t3 text-center text-white">
        การจัดส่งสินค้าของเรา มีหลายวิธี ขึ้นอยู่กับพื้นที่จัดส่ง
      </div>
    </div>


    <div class="row justify-content-center">
      <div class="th t4 text-start text-white col-9 mb-1">
        (1) จุดรับสินค้าอยู่ใกล้กับเส้นทางการจัดส่ง (สามารถตรวจสอบได้
        ได้จากพื้นที่ด้านล่าง) จะมีค่าบริการ 60.- และจัดส่งฟรี ในกรณีที่
        ยอดสินค้ามีมูลค่ารวมมากกว่า 1,000.- ขึ้นไป ต่อใบเสร็จ
      </div>
      <div class="th t4 text-start text-white col-9 mb-1">
        (2) สามารถนัดรับสินค้า ณ จุด Drop Point ของแต่ละเส้นทาง ได้ฟรี
        ตามวันและเวลาที่กำหนด
      </div>
      <div class="th t4 text-start text-white col-9">
        (3) สามารถนัดรับสินค้า ณ จุด Drop Point ของแต่ละเส้นทาง ได้ฟรี
        ตามวันและเวลาที่กำหนด
      </div>
    </div>

  </div>
  <div class="body">

    <div class="col-12 body-header-image text-center th t0 text-white">

      เส้นทางจัดส่งสินค้า
    </div>



    <div class="col-12">


      <img src="~@/assets/map.png" class="body-map-image" />


    </div>

    <div class="row justify-content-center pb-3" style="background-color: #6C8154 !important;">
      <div class="col-11 text-start mt-3">

        <div class="row mb-2">
          <div class="en t1 text-white text-start col-12">
            DROP POINT เส้นทางจัดส่งที่ 1
          </div>
        </div>

        <div class="row mb-4" v-for="(data, index) in map_data" :key="index">
          <!-- <div class="row mb-4"> -->
          <div class="en t2 text-white text-start col-5">

            <div class="image-container">
              <img src="~@/assets/pin.png" style="height: 100%;" />
              <div class="t3 text-black image-container-text">{{ index + 1 }}</div>
              <span>
                ชื่อพิกัด
              </span>
            </div>

          </div>
          <div class="en t2 text-white text-end col-7">
            <div class="button" @click="open_map(data.lat, data.lng)">
              <img class="googlepin" src="~@/assets/googlepin.png" />
              <span class="ms-2 text-end">
                Googles Maps
              </span>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>

  <div class="fotter ">

    <div class="row">
      <div class="col-12 body-header-image text-center th t2 pb-5" style="color: #6C8154 !important;">

        <div class="row justify-content-center">
          <div class="fotter-box button col-9 th t1 font-weight-normal mb-2" @click="check_register">
            ตรวจสอบเส้นทาง กดที่นี่

          </div>
        </div>

        สมัครสมาชิกกับตงศิริฟาร์ม กดเลย!
      </div>
    </div>
  </div>

</template>

<script>

import useSweetalert from "@/service/sweetalert2";
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "Home",
  setup() {
    const { Scustom2 } = useSweetalert();

    const map_data = [{
      "id": "1 ",
      "name": "จุดที่ 1 ",
      "lat": 14.16658043099513,
      "lng": 99.97437726827854,

    }
      , {
      "id": "2",
      "name": "จุดที่ 2 ",
      "lat": 14.16658043099513,
      "lng": 99.97437726827854,
    }
      , {
      "id": "3",
      "name": "จุดที่ 3",
      "lat": 14.16658043099513,
      "lng": 99.97437726827854,
    }
      , {
      "id": "2",
      "name": "จุดที่ 4",
      "lat": 14.16658043099513,
      "lng": 99.97437726827854,
    }
      , {
      "id": "2",
      "name": "จุดที่ 5",
      "lat": 14.16658043099513,
      "lng": 99.97437726827854,
    }
    ];



    const color = "#6C8154";
    const showLoading = ref(false);

    const check_register = () => {
      showLoading.value = true;
      setTimeout(() => {
        showLoading.value = false;

        // Scustom('<div class="container th"><h3>กรุณาสมัครสมาชิก</h3><h5>เพื่อใช้งานฟังก์ชั่นนี้</h5>'
        //   + '<div class="row mt-5">' + '<button id="swal-2-accept" class="swal-confirm-btn text-white">สมัครสมาชิก</button>' + '</div>'
        //   + '<div class="row mt-2">' + '<button id="swal-2-close" class="swal-cancle-btn">สินค้าของเรา</button>' + '</div>'
        //   + '</div>'
        // ).then(async (result) => {

        //   if (result.value) {
        //     console.log('confirm');
        //   } else {
        //     console.log('cancle');
        //   }

        // });



        Scustom2('<h3>กรุณาสมัครสมาชิก</h3> <h5>เพื่อใช้งานฟังก์ชั่นนี้</h5>', "สมัครสมาชิก", "สินค้าของเรา")
          .then(async (result) => {
            if (result.isConfirmed) {
              console.log('confirm');
            } else if (result.dismiss) {
              if (result.dismiss == 'cancle') {
                console.log('cancle');
              } else {
                console.log('close');
              }
            }
          });

      }, 500);



    }




    const open_map = (lat, lng) => {

      window.open(`https://www.google.com/maps?q=${lat},${lng}`, '_blank').focus();
    }



    return {
      map_data,
      check_register,
      open_map,
      showLoading,
      color
    };
  },
});
</script>

<style scope>
@import "~sweetalert2/dist/sweetalert2.css";
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import "~bootstrap-icons/font/bootstrap-icons.css";

.swal2-confirm {
  font-size: 24px !important;
  float: left;
  width: 80%;
  position: relative;
  box-sizing: border-box;
  border-style: solid !important;
  border-width: 2px !important;
  border-color: var(--color) !important;
  border-radius: 10px !important;
  background-color: var(--color) !important;
}

.swal2-cancel {
  float: right;
  width: 80%;
}

h3 {
  color: var(--color) !important;
}

h5 {
  color: var(--color) !important;
}

a:link {
  color: #ffffff !important;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: rgb(255, 255, 255);
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: rgb(158, 158, 158);
  background-color: transparent;
  text-decoration: none;
}

a:active {
  color: #6C8154;
  background-color: transparent;
  text-decoration: none;
}

html,
body {
  height: 100%;
}

.header {
  padding: 0;
  width: 100%;
  background-color: var(--color);
  height: auto;
}

.body {
  padding: 0;
  width: 100%;
  height: auto;
}

.body-header-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("~@/assets/header-img.png");
  background-color: var(--color_sub);
  width: 100%;
  padding: 20px 16px 20px 16px;
}

.body-map-image {
  object-fit: cover;
  width: 100%;
  height: auto;
}


.logo {
  position: absolute;
  top: 30px;
  left: 30px;
}

.googlepin {
  position: absolute;
  width: 30px;
  top: 10px;
  left: 6px;
}

.profile-pic {
  height: 50px;
  width: 50px;
  background-color: #DFDDDE;
  position: absolute;
  top: 50px;
  right: 15px;
  border-radius: 50%;
  border: 1px solid white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.header-box {
  color: #6C8154 !important;
  position: relative;
  box-sizing: border-box;
  height: auto;
  text-align: center;
  border-radius: 30px;
  background-color: var(--color_sub);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 8px 16px 8px 16px;
}

.fotter-box {
  color: #6C8154 !important;
  position: relative;
  box-sizing: border-box;
  height: auto;
  text-align: center;
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 16px 16px 16px 16px;
}

.image-container {
  position: relative;
  height: 50px;
}

.image-container-text {
  position: absolute;
  top: 18px;
  left: 17px;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 1;
}
</style>
